import logo from './assets/images/logo-white.png';
import { useState, createRef, useEffect } from 'react';

import './App.css';
import Countdown, { zeroPad } from 'react-countdown';
import {
  FaPlus,
  FaMinus,
  FaPlay,
  FaPause,
} from "react-icons/fa";

import {
  MdRefresh
} from 'react-icons/md';

function App() {
  const MINUTE_SECONDS = 1000 * 60;

  const [minutes, setMinutes] = useState(5);
  const [countdownTime, setCountdownTime] = useState(Date.now() + minutes);
  const [countdownKey, setCountdownKey] = useState(0);

  const ref = createRef();

  useEffect(() => {
    setCountdownTime(Date.now() + (MINUTE_SECONDS * minutes) )
  }, [MINUTE_SECONDS, minutes])

  const addTime = () => {
    setMinutes(minutes + 1);
    window.gtag('event', 'Increase', {
      'category': 'Countdown',
      'action': 'Increase',
      'minutes': minutes
    });
  }
  
  const substractTime = () => {
    setMinutes(minutes - 1);
    window.gtag('event', 'Decrease', {
      'category': 'Countdown',
      'action': 'Decrease',
      'minutes': minutes
    });
  }

  const startCountdown = () => {
    setCountdownKey(countdownKey + 1);
    ref.current.api.start();

    window.gtag('event', 'Play', {
      'category': 'Countdown',
      'action': 'Play',
      'minutes': minutes
    });
  };

  const pauseCountdown = () => {
    setCountdownKey(countdownKey + 1);
    ref.current.api.pause();
    window.gtag('event', 'Pause', {
      'category': 'Countdown',
      'action': 'Pause',
      'minutes': minutes
    });
  }

  const resetCountdown = () => {
    setCountdownTime(countdownTime);
    ref.current.api.stop();
    window.gtag('event', 'Reset', {
      'category': 'Countdown',
      'action': 'Reset',
      'minutes': minutes
    });
  }

  const renderer = ({ minutes, seconds }) => (
    <span>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );

  return (
    <div className="App">
      <div>
        <header className='App-header'>
          <img src={logo} className='App-logo' alt='logo' />
          <div className='App-countdown'>
            <Countdown autoStart={false} date={countdownTime} count ref={ref} renderer={renderer} />
          </div>
        </header>
        <div className='buttons'>
          <button className='App-button' onClick={addTime}>
            <FaPlus />
          </button>
          <button className='App-button' onClick={substractTime}>
            <FaMinus />
          </button>
          <button className='App-button' onClick={startCountdown}>
            <FaPlay />
          </button>
          <button className='App-button' onClick={pauseCountdown}>
            <FaPause />
          </button>
          <button className='App-button' onClick={resetCountdown}>
            <MdRefresh />
          </button>
        </div>
      </div>
    </div>
  );
}
export default App;
